
import { defineComponent, PropType } from 'vue'
import { NavbarProps } from '@/API/types'
export default defineComponent({
    props: {
      navArr: {
        default: () => [],
        type: Array as PropType<NavbarProps[]>
      },

    },
    name: 'Navigation Bar',
    setup () {
      return {

      }
    }
 });
