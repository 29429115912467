import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { id: "mian" }
const _hoisted_3 = { style: {"font-size":"16px","font-weight":"600"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_badge = _resolveComponent("a-badge")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navArr, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "navtag",
            key: item.id
          }, [
            _createVNode(_component_a_badge, {
              count: item.count
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: item.query ? { path: item.path, query: { pgmId: item.query.pgmId}} : item.path
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1),
                    _createElementVNode("i", {
                      class: _normalizeClass(["font_family tagicon", item.icon])
                    }, null, 2)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1032, ["count"])
          ]))
        }), 128))
      ])
    ])
  ]))
}